/// <reference path="o365.pwa.declaration.shared.dexie.objectStores.App.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { Database } from 'o365.pwa.declaration.shared.dexie.objectStores.Database.d.ts';
import type { PWAState } from 'o365.pwa.declaration.shared.dexie.objectStores.PWAState.d.ts';
import type { ServiceWorkerState } from 'o365.pwa.declaration.shared.dexie.objectStores.ServiceWorkerState.d.ts';

import type * as AppModule from 'o365.pwa.declaration.shared.dexie.objectStores.App.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const indexedDBHandlerImportmapEntry = self.o365.getImportMapEntryFromImportUrl('o365.pwa.modules.sw.IndexedDBHandler.ts');

    class App implements AppModule.App {
        static objectStoreDexieSchema: string = "&id";

        id!: string;
        title?: string;
        icon?: string;

        public get databases() {
            const app = this;

            return new Proxy<AppModule.Databases>(<AppModule.Databases>{
                getAll: async () => {
                    const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);

                    return await IndexedDBHandler.getDatabases(app.id);
                }
            }, {
                get(target, prop, receiver) {
                    if (prop in target) {
                        return Reflect.get(target, prop, receiver);
                    }

                    return new Promise<Database | null>(async (resolve, reject) => {
                        try {
                            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);

                            const database = await IndexedDBHandler.getDatabase(app.id, prop.toString());

                            resolve(database);
                        } catch (reason) {
                            reject(reason);
                        }
                    });
                }
            });
        }

        public get pwaState(): Promise<PWAState | null> {
            return new Promise(async (resolve, reject) => {
                try {
                    const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);

                    const pwaState = await IndexedDBHandler.getPWAState(this.id);

                    resolve(pwaState);
                } catch (reason) {
                    reject(reason);
                }
            });
        }

        public get serviceWorkerState(): Promise<ServiceWorkerState | null> {
            return new Promise(async (resolve, reject) => {
                try {
                    const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);
                    
                    const serviceWorkerState = await IndexedDBHandler.getServiceWorkerState(this.id);

                    resolve(serviceWorkerState);
                } catch (reason) {
                    reject(reason);
                }
            });
        }

        public get isAppInstalled(): Promise<boolean> {
            return new Promise(async (resolve, reject) => {
                try {
                    const pwaState = await this.pwaState;
                    const serviceWorkerState = await this.serviceWorkerState;

                    const isAppInstalled = pwaState?.isAppInstalled ?? false;
                    const isServiceWorkerInstalled = serviceWorkerState?.installed ?? false;

                    resolve(isAppInstalled && isServiceWorkerInstalled);
                } catch (reason) {
                    reject(reason);
                }
            });
        }

        constructor(id: string, title?: string, icon?: string) {
            this.id = id;
            this.title = title;
            this.icon = icon;
        }

        public async save(): Promise<void> {
            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);

            await IndexedDBHandler.updateApp(this);
        }

        public async delete(): Promise<void> {
            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);
            
            await IndexedDBHandler.deleteApp(this);
        }

        public async forceReload(): Promise<App | null> {
            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);
            
            return await IndexedDBHandler.getAppFromIndexedDB(this.id);
        }

        public async initialize(): Promise<void> {
            const databases = await this.databases.getAll();

            for (const database of databases) {
                await database.initialize();
            }
        }
    }

    self.o365.exportScripts({ App });
})();